<template>
<div id="app" class="content-container">
        
  <mds-row align-vertical="center">
    <mds-col>
      <mds-section class="topic-name" container title="MVO Optimization" bold>
        <mds-form size="medium">
              
        <mds-required-field-key size="small" text="Required fields" ></mds-required-field-key>
        <mds-fieldset size="medium" horizontal>
          <mds-select size="medium" label="Asset Class" placeholder="Choose One" microcopy-above="Please select one." required :options="[ { text: 'Fund', value: 'fund' }, { text: 'Equity', value: 'equity' } ]" v-model="StrategySpec.assetClass"></mds-select>
          <mds-radio-button v-if="StrategySpec.assetClass === 'equity'" size="medium" name="medium-example" value="['exposures','idiosyncratic_matrix','factor_covariance_matrix','expected_returns','index_exp_weights']" microcopy="exposures,idiosyncratic_matrix,risk_aversion,expected_returns,factor_covariance_matrix" checked v-model="StrategySpec.optimizerConstants">Optimizer Constants</mds-radio-button>
        </mds-fieldset>
        <mds-fieldset size="medium" horizontal>  
          <mds-combo-box label="Constraints" microcopy-above="Multi Select" required multiple :data-set="[ { text: 'Holding Weights', value: 'holding_weights', }, { text: 'Fully Invested', value: 'fully_invested', }, { text: 'Net Group Exposure', value: 'net_group_exposure', }, ]" v-model="StrategySpec.Name"></mds-combo-box>
          
          <mds-input size="medium" label="End Date" placeholder="DD-MM-YYYY" microcopy-above="Please enter end date." error-text:="['Missing end date']" required type="date" v-model="UniverseDefinition.endDate"></mds-input>
        </mds-fieldset>  
        <mds-fieldset size="medium" horizontal>
          <mds-input v-if="StrategySpec.Name.includes('holding_weights')" size="medium" label="Holdings Weight" placeholder="comma separated values eg:- 0.0,0.1" microcopy-above="Comma Separated." error-text:="['Missing Holdings weight']" required type="text" v-model="StrategySpec.holding_weights"></mds-input>
          <mds-input v-if="StrategySpec.Name.includes('fully_invested')" size="medium" label="Fully Invested" placeholder="comma separated values eg:- 1,1" microcopy-above="Comma Separated." error-text:="['Missing Fully Invested']" required type="text" v-model="StrategySpec.fully_invested"></mds-input>              
          <mds-input v-if="StrategySpec.Name.includes('net_group_exposure')" size="medium" label="Net Group Exposure" placeholder="comma separated values eg:- 1,1" microcopy-above="Comma Separated." error-text:="['Missing Fully Invested']" required type="text" v-model="StrategySpec.net_group_exposure"></mds-input>    
        </mds-fieldset>  
        <mds-fieldset size="medium" horizontal>  
          <mds-select size="medium" label="Manual Asset List" placeholder="Choose One" microcopy-above="Please select one from the list." required :options="[ { text: 'Yes', value: 'yes' }, { text: 'No', value: 'no' } ]" v-model="UniverseDefinition.manualAssetlist"></mds-select>
          <mds-input v-if="UniverseDefinition.manualAssetlist === 'yes'" size="medium" label="Asset List" placeholder="[0P00009RUB],[0P00009RUB]..." microcopy-above="Please enter asset list." error-text:="['Missing asset list']" required type="text" v-model="UniverseDefinition.assetList"></mds-input>
        </mds-fieldset>
        <mds-input size="medium" required label="Email" v-model="email"></mds-input>
        </mds-form>
      <mds-row class="button-center-align">
        <mds-button-container >
          <mds-button variation="primary" size="large" @click="formSubmit()"> Submit </mds-button>
        </mds-button-container> 
      </mds-row>
        <mds-button-container rightAligned=true>
        <mds-button v-if="tabflag === 'false'" variation="secondary" icon="envelope" type="button"  @click="sendmail()"> Email Results </mds-button>
        </mds-button-container> 
      <!-- {{datalist}} -->
      </mds-section>  
      <mds-loader v-if="loading === 'true'"></mds-loader>
      <mds-tabs v-if="tabflag === 'false' && StrategySpec.assetClass === 'equity'" variation="site-navigation" alignment="center" size="large" :content="tabsContent_equity" v-on:mds-tabs-item-active="setActiveItem"></mds-tabs>
      <mds-tabs v-if="tabflag === 'false' && StrategySpec.assetClass === 'fund'" variation="site-navigation" alignment="center" size="large" :content="tabsContent_fund" v-on:mds-tabs-item-active="setActiveItem"></mds-tabs>
      <!-- <div v-if="tabflag === 'false' && activeTab === 'asset-list'" class="gen-def">Asset List</div> -->
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'asset-list' && StrategySpec.assetClass === 'equity'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="output_header_equity" :row-data="datalist.output" ></mds-data-table>
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'asset-list' && StrategySpec.assetClass === 'fund'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="output_header_fund" :row-data="datalist.output" ></mds-data-table>
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'sector-constraints'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="sector_cons_header" :row-data="datalist.sector_constraints" ></mds-data-table>
      <!-- <mds-col><mds-exhibit v-if="tabflag === 'false' && activeTab === 'sector-constraints'" caption="Pie Chart"><img class="image" :src="datalist.pie_chart" alt="Pie Chart" style="width:100%"></mds-exhibit></mds-col> -->
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'final-result' && StrategySpec.assetClass === 'equity'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="final_header_equity" :row-data="datalist.final_result" ></mds-data-table>
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'final-result' && StrategySpec.assetClass === 'fund'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="final_header_fund" :row-data="datalist.final_result" ></mds-data-table>
    </mds-col>
  </mds-row>
  <div  v-if="tabflag === 'false' && activeTab === 'charts'"> 
  <img class="image" v-for="(image, i) in images" :src="image" :key="i" @click="index = i">
  </div>
  <vue-gallery-slideshow v-if="tabflag === 'false' && activeTab === 'charts'" :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
      
</div>

</template>

<script>
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsRequiredFieldKey from '@mds/required-field-key'
import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsRadioButton from '@mds/radio-button'
import MdsSection from '@mds/section'
import { MdsRow, MdsCol } from '@mds/layout-grid'
import MdsSelect from '@mds/select'
import MdsComboBox from '@mds/combo-box'
import MdsInput from '@mds/input'
import MdsLoader from '@mds/loader'
import { MdsDataTable } from '@mds/data-table'
import MdsTabs from '@mds/tabs'
import mvo_json from "./json/mvo/strategy_spec_mvo.json"
import mvo_univ_json from "./json/mvo/universe_definition_mvo.json"
import mvo_output_header_equity from "./json/mvo/output_header_mvo_equity.json"
import mvo_output_header_fund from "./json/mvo/output_header_mvo_fund.json"
import outtabdata_equity from "./json/mvo/tab_content_mvo_equity.json"
import outtabdata_fund from "./json/mvo/tab_content_mvo_fund.json"
import sector_cons from "./json/mvo/sector_con_header_mvo.json"
import final_out_equity from "./json/mvo/final_result_bt_equity.json"
import final_out_fund from "./json/mvo/final_result_bt_fund.json"
import VueGallerySlideshow from 'vue-gallery-slideshow';


export default {
    name: 'mvo',
    data(){
      return{
        StrategySpec:mvo_json,
        UniverseDefinition:mvo_univ_json,
        loading: false,
        datalist:undefined,
        output_header_equity: mvo_output_header_equity,
        output_header_fund: mvo_output_header_fund,
        tabsContent_equity: outtabdata_equity,
        tabsContent_fund: outtabdata_fund,
        activeTab: 'asset-list',
        tabflag: 'true',
        sector_cons_header:sector_cons,
        final_header_equity: final_out_equity,
        final_header_fund: final_out_fund,
        email:'',
        index: null,
        images: [
         
        ]
      }

    },
  components: {
    MdsForm,
    MdsFieldset,
    MdsRadioButton,
    MdsRow, 
    MdsCol,
    MdsSection,
    MdsRequiredFieldKey,
    MdsSelect,
    MdsComboBox,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
    MdsDataTable,
    MdsTabs,
    VueGallerySlideshow
  },
  methods: {
            formSubmit() {
                this.loading = 'true',
                this.axios.post('https://optimizer-api-stg.qua9cf48.easn.morningstar.com/optimizer_api/mvobt',
                {'StrategySpec':this.StrategySpec,
                 'UniverseDef': this.UniverseDefinition},
                { headers: {'Content-Type': 'application/json'}})
                .then((resp) => {
                  this.reset();
                  this.datalist= resp.data;
                  this.images= resp.data.images
                  console.warn(resp.data)
                })
                
            },
            sendmail() {
              this.axios.post('https://ocr3ew4bjf.execute-api.us-east-1.amazonaws.com/v1/sendmail',
              {'output':this.datalist,
               'email':this.email},
              { headers: {'Content-Type': 'application/json'}})
              .then((resp) => {
                  // this.reset();
                  console.warn(resp.data)
                })
            },
            reset() {
              this.loading = 'false';
              this.tabflag = 'false'
            },
            setActiveItem(event) {
        const newTabId = event.currentTarget.id;

        this.tabsContent_equity.forEach(item => {
            if (item.id === newTabId) {
                item.active = true;
            } else {
                item.active = false;
            }
        });
        this.tabsContent_fund.forEach(item => {
            if (item.id === newTabId) {
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.activeTab = newTabId;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'tabButtonClick',
          'tabButtonId': newTabId
        });
      }
  }
}

</script>

<style lang='scss'>
    @import "./src/style/style.scss";

</style>
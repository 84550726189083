<template>
<div id="app" class="content-container">
        
  <mds-row align-vertical="center">
    <mds-col>
      <mds-section class="topic-name" container title="Tracking Error" bold>
        <mds-form size="medium">
              
        <mds-required-field-key size="small" text="Required fields" ></mds-required-field-key>
        <mds-fieldset size="medium" horizontal>
          <mds-select size="medium" label="Asset Class" placeholder="Choose One" microcopy-above="Please select one." required :options="[{ text: 'Equity', value: 'equity' } ]" v-model="StrategySpec.assetClass"></mds-select>
          <mds-radio-button v-if="StrategySpec.assetClass === 'equity'" size="medium" name="medium-example" value="['exposures','idiosyncratic_matrix','factor_covariance_matrix','expected_returns','index_exp_weights']" microcopy="exposures,idiosyncratic_matrix,risk_aversion,expected_returns,factor_covariance_matrix" checked v-model="StrategySpec.optimizerConstants">Optimizer Constants</mds-radio-button>
        </mds-fieldset>
        <mds-fieldset size="medium" horizontal>  
          <mds-combo-box label="Constraints" microcopy-above="Multi Select" required multiple :data-set="[ { text: 'Holding Weights', value: 'holding_weights', }, { text: 'Fully Invested', value: 'fully_invested', }, { text: 'Net Group Exposure', value: 'net_group_exposure', }, ]" v-model="StrategySpec.Name"></mds-combo-box>
          
          <mds-input size="medium" label="End Date" placeholder="DD-MM-YYYY" microcopy-above="Please enter end date." error-text:="['Missing end date']" required type="date" v-model="UniverseDefinition.endDate"></mds-input>
        </mds-fieldset>  
        <mds-fieldset size="medium" horizontal>
          <mds-input v-if="StrategySpec.Name.includes('holding_weights')" size="medium" label="Holdings Weight" placeholder="comma separated values eg:- 0.0,0.1" microcopy-above="Comma Separated." error-text:="['Missing Holdings weight']" required type="text" v-model="StrategySpec.holding_weights"></mds-input>
          <mds-input v-if="StrategySpec.Name.includes('fully_invested')" size="medium" label="Fully Invested" placeholder="comma separated values eg:- 1,1" microcopy-above="Comma Separated." error-text:="['Missing Fully Invested']" required type="text" v-model="StrategySpec.fully_invested"></mds-input>              
          <mds-input v-if="StrategySpec.Name.includes('net_group_exposure')" size="medium" label="Net Group Exposure" placeholder="comma separated values eg:- 1,1" microcopy-above="Comma Separated." error-text:="['Missing Net Group Exposure']" required type="text" v-model="StrategySpec.net_group_exposure"></mds-input>    
        </mds-fieldset>  
        <mds-fieldset size="medium" horizontal>  
          <mds-select size="medium" label="Benchmark Portfolio" placeholder="Choose One" microcopy-above="Please select one from the list." required :options="[ { text: 'FTSE100', value: 'FTSE100' },]" v-model="UniverseDefinition.portfolio"></mds-select>
        </mds-fieldset>
        <mds-input size="medium" required label="Email" v-model="email"></mds-input>      
        </mds-form>
      <mds-row class="button-center-align">
        <mds-button-container>
          <mds-button variation="primary" size="large" @click="formSubmit()"> Submit </mds-button>
        </mds-button-container> 
      </mds-row>
      <mds-button-container rightAligned=true>
        <mds-button v-if="tabflag === 'false'" variation="secondary" icon="envelope" type="button"  @click="sendmail()"> Email Results </mds-button>
        </mds-button-container> 
      <!-- {{datalist}} -->
      </mds-section>  
      <mds-loader v-if="loading === 'true'"></mds-loader>
      <mds-tabs v-if="tabflag === 'false'" variation="site-navigation" alignment="center" size="large" :content="tabsContent" v-on:mds-tabs-item-active="setActiveItem"></mds-tabs>
      <div class="gen-def" v-if="tabflag === 'false' && activeTab === 'general'">The tracking error is {{datalist.tracking_error}}</div>
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'asset-list'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="output_header" :row-data="datalist.output" ></mds-data-table>
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'sector-constraints'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="sector_cons_header" :row-data="datalist.sector_constraints" ></mds-data-table>
      
      <mds-data-table v-if="tabflag === 'false' && activeTab === 'final-result'" pagination="below" row-hover :pagination-config="{ totalItems: 5, showItemsInfo: true, showItemsSelect: true, }" :header-configs="final_header" :row-data="datalist.final_result" ></mds-data-table>
    </mds-col>
  </mds-row>
  <div  v-if="tabflag === 'false' && activeTab === 'charts'">
  <img class="image" v-for="(image, i) in images" :src="image" :key="i" @click="index = i">
  </div>
  <vue-gallery-slideshow v-if="tabflag === 'false' && activeTab === 'charts'" :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
  
</div>

</template>

<script>
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsRequiredFieldKey from '@mds/required-field-key'
import { MdsButton, MdsButtonContainer } from '@mds/button'
import MdsRadioButton from '@mds/radio-button'
import MdsSection from '@mds/section'
import { MdsRow, MdsCol } from '@mds/layout-grid'
import MdsSelect from '@mds/select'
import MdsComboBox from '@mds/combo-box'
import MdsInput from '@mds/input'
import MdsLoader from '@mds/loader'
import { MdsDataTable } from '@mds/data-table'
import MdsTabs from '@mds/tabs'
import te_json from "./json/tracking_error/strategy_spec_tracking_error.json"
import te_univ_json from "./json/tracking_error/universe_definition_tracking_error.json"
import tracking_error_output_header from "./json/tracking_error/output_header_tracking_error.json"
import outtabdata from "./json/tracking_error/tab_content_tracking_error.json"
import sector_cons from "./json/tracking_error/sector_con_header_tracking_error.json"
import final_out from "./json/tracking_error/final_result_bt.json"
import VueGallerySlideshow from 'vue-gallery-slideshow';



export default {
    name: 'trackingerror',
    data(){
      return{
        StrategySpec:te_json,
        UniverseDefinition:te_univ_json,
        loading: false,
        datalist:undefined,
        output_header: tracking_error_output_header,
        tabsContent: outtabdata,
        activeTab: 'general',
        tabflag: 'true',
        sector_cons_header:sector_cons,
        final_header:final_out,
        // output_loc:'afsdafasfasf',
        index: null,
        images: [
         
        ]
      }

    },
  components: {
    MdsForm,
    MdsFieldset,
    MdsRadioButton,
    MdsRow, 
    MdsCol,
    MdsSection,
    MdsRequiredFieldKey,
    MdsSelect,
    MdsComboBox,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
    MdsDataTable,
    MdsTabs,
    VueGallerySlideshow

  },
  methods: {
             formSubmit() {
                this.loading = 'true',
                
                this.axios.post('https://optimizer-api-stg.qua9cf48.easn.morningstar.com/optimizer_api/tracking_errorbt',
                {'StrategySpec':this.StrategySpec,
                 'UniverseDef': this.UniverseDefinition},
                { headers: {'Content-Type': 'application/json'}})
                // .then((resp) => {
                  
                //   console.warn(resp.data)
                //   this.response();
                // })
                .then((resp) => {
                  this.reset();
                  this.datalist= resp.data;
                  this.images= resp.data.images
                  console.warn(resp.data)
                })
                
            },
            sendmail() {
              this.axios.post('https://ocr3ew4bjf.execute-api.us-east-1.amazonaws.com/v1/sendmail',
              {'output':this.datalist,
               'email':this.email},
              { headers: {'Content-Type': 'application/json'}})
              .then((resp) => {
                  // this.reset();
                  console.warn(resp.data)
                })
            },
            reset() {
              this.loading = 'false';
              this.tabflag = 'false'
            },
            setActiveItem(event) {
        const newTabId = event.currentTarget.id;

        this.tabsContent.forEach(item => {
            if (item.id === newTabId) {
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.activeTab = newTabId;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'tabButtonClick',
          'tabButtonId': newTabId
        });
      }
  }
}
</script>

<style lang='scss'>
    @import "./src/style/style.scss";
</style>
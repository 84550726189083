<template>
<div id="app" class="content-container">
    <mds-layout-grid>
        <mds-row class="my-row" align-vertical="center">
            <mds-col :cols="4">    
            </mds-col>
            <mds-col :cols="6">
                <div class="logo">
                    <img class="logoImage" alt="Morningstar" src="./assets/mstar-logo.svg" />
                </div>
            </mds-col>
        </mds-row>
        <mds-row class="my-row" align-vertical="center">
          <mds-col>
            <div class="data-point">
              <div class="topic-name">Quant Optimizer API</div>
            </div>
          </mds-col>
        </mds-row>
        <div class="def-name">Portfolio optimization allows investors aim to achieve the maximum return at a given risk, or to minimize the risk at a given return rate subject to certain constraints. An optimal portfolio is a lot more than a long list of good stocks and bonds – it is a balanced whole, protecting investors from too much exposure to market factors while also maximizing their utility function. Portfolio optimization, therefore, is the process of selecting the best portfolio out of the set of all the portfolios that maximize the investor’s utility</div>
        <div class="short-desc">use below form tabs</div>
        <mds-tabs  variation="site-navigation" alignment="center" size="large" :content="tabsContent" v-on:mds-tabs-item-active="setActiveItem"></mds-tabs>
        <mvo v-if="activeTab === 'mvo'"></mvo>
        <trackingerror v-if="activeTab === 'tracking_error'"></trackingerror>
        <best_k v-if="activeTab === 'best_k'"></best_k>
    </mds-layout-grid>
</div>

</template>
<script>
import mvo from "./components/mvo.vue"
import trackingerror from "./components/trackingerror.vue"
import best_k from "./components/best_k.vue"
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid'
import MdsTabs from '@mds/tabs'
import tab_content from "./components/json/function_tab.json"


export default {

  name: 'App',

    data(){
    return{
        
        tabsContent: tab_content,
        activeTab: 'mvo',
    }
  },
  components: {
    MdsLayoutGrid, 
    mvo,
    MdsRow, 
    MdsCol,
    MdsTabs,
    trackingerror,
    best_k
  },
methods: {
            
            setActiveItem(event) {
        const newTabId = event.currentTarget.id;

        this.tabsContent.forEach(item => {
            if (item.id === newTabId) {
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.activeTab = newTabId;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'tabButtonClick',
          'tabButtonId': newTabId
        });
      }
        }
}
</script>

<style lang='scss'>
    @import "./style/style.scss";
</style>